$mainColor: #15023a;

$width: 768px;

* {
  scrollbar-width: none; //for firefox
  &::-webkit-scrollbar {
    display: none;
  }
}
@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}
