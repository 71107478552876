@import "../../global.scss";

.footer {
  width: 100%;
  height: 70px;
  background: linear-gradient(-45deg, #b2dcf0, #ecc5d4, #a0c0f1, #ec9292);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  color: $mainColor;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  z-index: 3;
  transition: all 1s ease;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .wrapper a {
    overflow: hidden;
    max-height: 70px;
    display: inline-flex;
    justify-content: center;
    width: 50%;
    padding: 5px;

    flex-direction: row;
  }
  .img {
    max-height: 60px;
  }
}
